import { useSearchParams } from "react-router-dom";

export default function ViewContent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filePath = searchParams.get("file_path");

  return (
    <iframe
      src={`${process.env.REACT_APP_SERVER_URL}/contents/${filePath}`}
    ></iframe>
  );
}
