import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function ViewZipContent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filePath = searchParams.get("file_path");

  function x(varname, varvalue) {
    try {
      window.SCORM_DATA_HRPLUS_[varname] = varvalue;
    } catch {}

    return "true";
  }

  function x2(varname) {
    if (window.SCORM_DATA_HRPLUS_[varname] != undefined)
      return window.SCORM_DATA_HRPLUS_[varname];
    return "";
  }

  useEffect(() => {
    window.SCORM_DATA_HRPLUS_ = {};

    window.API = {
      LMSInitialize: () => "true",
      LMSGetValue: () => "true",
      LMSGetValue: x2,
      LMSSetValue: x,
      LMSCommit: () => "true",
      LMSFinish: () => "true",
      LMSGetLastError: () => "",
      LMSGetDiagnostic: () => "",
      LMSGetErrorString: () => "",
    };

    window.API_1484_11 = {
      Initialize: () => "true",
      GetValue: () => "true",
      GetValue: x2,
      SetValue: x,
      Commit: () => "true",
      Finish: () => "true",
      Terminate: () => "true",
      GetLastError: () => "",
      GetDiagnostic: () => "",
      GetErrorString: () => "",
    };

    return () => {
      window.API_1484_11 = undefined;
      window.API = undefined;
      window.SCORM_DATA_HRPLUS_ = undefined;
    };
  }, []);

  return (
    <iframe
      src={`${process.env.REACT_APP_SERVER_URL}/contents/${filePath}`}
    ></iframe>
  );
}
