import axios from "axios";
import { useRef, useState } from "react";
import LastLinks from "../Components/last-links";
// import { useNavigate } from "react-router-dom";

export default function UploadContent() {
  const inputRef = useRef(null);
  //   const navigate = useNavigate();

  const [fileLink, setFileLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  function upload(e) {
    e.preventDefault();
    setIsLoading(true);

    const file = inputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded);
          setLoadingPercentage(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
          return;
        }
        // save the link in local storage
        updateLocalStorage(
          `${window.location.origin}/view?file_path=${res.data.file_path}`
        );
        setFileLink(`view?file_path=${res.data.file_path}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateLocalStorage(link) {
    const lastLinks = JSON.parse(localStorage.getItem("last_links"));
    if (!lastLinks) {
      localStorage.setItem("last_links", JSON.stringify([link]));
    } else {
      lastLinks.unshift(link);
      localStorage.setItem("last_links", JSON.stringify(lastLinks));
    }
  }

  return (
    <>
      <LastLinks />
      <div className="upload-page">
        <h1>Upload File</h1>

        <form onSubmit={upload}>
          <input type="file" name="file" ref={inputRef} />
          <input
            type="submit"
            disabled={isLoading}
            value={isLoading ? "Loading..." : "Upload"}
          />
          {isLoading && loadingPercentage + "%"}
        </form>

        {fileLink && (
          <div>
            <h3>Your Link:</h3>
            <div>
              <a className="link-output" target="_blank" href={fileLink}>
                {`${window.location.origin}/${fileLink}`}
              </a>
            </div>
            <p style={{ color: "gray" }}>
              *This link will be removed in 10 days
            </p>
          </div>
        )}
      </div>
    </>
  );
}
