import "./App.css";
import UploadContent from "./Pages/upload.page";
import ViewContent from "./Pages/view.page";
import ViewZipContent from "./Pages/view-zip.page";
import DownloadContent from "./Pages/download.page";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<UploadContent />} />
          <Route path="/view/:fileName" element={<ViewContent />} />
          <Route path="/view" element={<ViewZipContent />} />
          <Route path="/download" element={<DownloadContent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
