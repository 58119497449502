export default function LastLinks() {
  const lastLinks = JSON.parse(localStorage.getItem("last_links"));

  return lastLinks ? (
    <div className="last-links">
      <h2>Last Links</h2>
      <ul>
        {lastLinks.map((link, index) => (
          <li key={index}>
            <a target="_blank" href={link}>
              {link}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
